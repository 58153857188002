import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { axios } from '../lib';
import { User } from '../models';
import { URL } from '../config';

export default function useCurrentUser() {
  let token: string = null;
  if (typeof window !== 'undefined') {
    token = localStorage.getItem('token');
  }
  const query = useQuery<User, AxiosError>('me', async () => {
    if (!token) return null;
    const { data } = await axios.get(`${URL}/me`, { 
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
      },
    });
    return data;
  });
  return query;
}

import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { axios } from '../lib';
import { Product, PaginationInfo } from '../models';
import { URL } from '../config';

interface AddCommentToProduct {
  text?: string;
  rating?: number;
  product?: string;
  client?: string;
}

type TUseProductPagination = {
  isSearch?: boolean;
  search?: string;
  sort?: Partial<Record<string, number>>;
};

export function useProductPagination(
  page: number,
  perPage: number,
  options: TUseProductPagination
) {
  const { search } = options;
  const query = useQuery<PaginationInfo<Product>, AxiosError>(
    `${
      options.isSearch
        ? `product-search-${search}:${page}:${perPage}`
        : `product-pagination:${page}:${perPage}:${
            options.isSearch === false ? JSON.stringify(options?.sort) : ''
          }`
    }`,
    async () => {
      if (options.isSearch) {
        const { data } = await axios.post<
          { search: string },
          AxiosResponse<PaginationInfo<Product>>
        >(`${URL}/products/search/${page}/${perPage}`, {  
          search: decodeURIComponent(search),
        });
        return data;
        // eslint-disable-next-line no-else-return
      } else {
        const { data } = await axios.post<
          any,
          AxiosResponse<PaginationInfo<Product>>
        >(`${URL}/products/pagination/${page}/${perPage}`, {
          sort: options?.isSearch === false ? options?.sort ?? {} : {},
        });
        return data;
      }
    }
  );
  return query;
}

type TSearchFiltered = {
  categories: string[];
  subcategories: string[];
  sort?: Partial<Record<string, number>>;
};

export function useProductFiltered(
  page: number,
  perPage: number,
  search: TSearchFiltered
) {
  const query = useQuery<PaginationInfo<Product>, AxiosError>(
    `${`search-categories-${search?.categories?.join(
      '-'
    )}${search?.subcategories?.join('-')}:${page}:${perPage}`}${JSON.stringify(
      search?.sort ?? {}
    )}`,
    async () => {
      const { data } = await axios.post<
        {
          page: number;
          perPage: number;
          categories: string[];
          subcategories: string[];
          sort: Partial<Record<string, number>>;
        },
        AxiosResponse<PaginationInfo<Product>>
      >(`${URL}/products/search/categories`, {
        page,
        perPage,
        categories: search?.categories ?? [],
        subcategories: search?.subcategories ?? [],
        sort: search?.sort ?? {},
      });
      return data;
    }
  );
  return query;
}

export function useProduct(id: string) {
  const query = useQuery<Product, AxiosError>(`product:${id}`, async () => {
    const { data } = await axios.get<Product>(`${URL}/products/${id}`);
    return data;
  });
  return query;
}

export function useAddComment() {
  const client = useQueryClient();
  const mutation = useMutation<Product, AxiosError, AddCommentToProduct>(
    async (values: AddCommentToProduct) => {
      const { data } = await axios.post<
        AddCommentToProduct,
        AxiosResponse<Product>
      >(`${URL}/products/add-comment`, values);
      return data;
    },
    {
      onSuccess: async (data: any) => {
        await client.setQueryData(`product:${data._id}`, data);
      },
    }
  );
  return mutation;
}

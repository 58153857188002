import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { axios } from '../lib';
import { ShopCart, User } from '../models';
import useUser from './useUser';
import { URL } from '../config';

interface AddItemToCardInput {
  shopCartId: string;
  productId: string;
  quantity: number;
}

interface RemoveItemFromCartInput {
  shopCartId: string;
  orderProductId: string;
}

interface IncreaseOneItemOfCartInput {
  shopCartId: string;
  orderProductId: string;
}

interface DecreaseOneItemOfCartInput {
  shopCartId: string;
  orderProductId: string;
}

export function useAddItemToCart() {
  const client = useQueryClient();
  const [, setUser] = useUser();
  const mutation = useMutation<ShopCart, AxiosError, AddItemToCardInput>(
    async (values: AddItemToCardInput) => {
      const { data } = await axios.post<
        AddItemToCardInput,
        AxiosResponse<ShopCart>
      >(`${URL}/shop-carts/add-item`, values); 
      return data;
    },
    {
      onSuccess: (data) => {
        client.setQueryData('me', (prev: User) => {
          const user = {
            ...(prev ?? {}),
            client: {
              ...(prev?.client ?? {}),
              shopCart: data,
            },
          };
          setUser(user);
          return user;
        });
      },
    }
  );
  return mutation;
}

export function useDeleteItemFromCart() {
  const client = useQueryClient();
  const [, setUser] = useUser();
  const mutation = useMutation<ShopCart, AxiosError, RemoveItemFromCartInput>(
    async (values: RemoveItemFromCartInput) => {
      const { data } = await axios.post<
        RemoveItemFromCartInput,
        AxiosResponse<ShopCart>
      >(`${URL}/shop-carts/delete-item`, values); 
      return data;
    },
    {
      onSuccess: (data) => {
        client.setQueryData('me', (prev: User) => {
          const user = {
            ...(prev ?? {}),
            client: {
              ...(prev?.client ?? {}),
              shopCart: data,
            },
          };
          setUser(user);
          return user;
        });
      },
    }
  );
  return mutation;
}

export function useIncreaseOneItemOfCart() {
  const client = useQueryClient();
  const [, setUser] = useUser();
  const mutation = useMutation<
    ShopCart,
    AxiosError,
    IncreaseOneItemOfCartInput
  >(
    async (values: IncreaseOneItemOfCartInput) => {
      const { data } = await axios.post<
        IncreaseOneItemOfCartInput,
        AxiosResponse<ShopCart>
      >(`${URL}/shop-carts/increase-item-cart`, values); 
      return data;
    },
    {
      onSuccess: (data) => {
        client.setQueryData('me', (prev: User) => {
          const user = {
            ...(prev ?? {}),
            client: {
              ...(prev?.client ?? {}),
              shopCart: data,
            },
          };
          setUser(user);
          return user;
        });
      },
    }
  );
  return mutation;
}

export function useDecreaseOneItemOfCart() {
  const client = useQueryClient();
  const [, setUser] = useUser();
  const mutation = useMutation<
    ShopCart,
    AxiosError,
    DecreaseOneItemOfCartInput
  >(
    async (values: DecreaseOneItemOfCartInput) => {
      const { data } = await axios.post<
        DecreaseOneItemOfCartInput,
        AxiosResponse<ShopCart>
      >(`${URL}/shop-carts/decrease-item-cart`, values); 
      return data;
    },
    {
      onSuccess: (data) => {
        client.setQueryData('me', (prev: User) => {
          const user = {
            ...(prev ?? {}),
            client: {
              ...(prev?.client ?? {}),
              shopCart: data,
            },
          };
          setUser(user);
          return user;
        });
      },
    }
  );
  return mutation;
}

import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { axios } from '../lib';
import { Setting } from '../models';
import { URL } from '../config';

export function useSetting() {
  const query = useQuery<any, AxiosError, Setting>('setting', async () => {
    const { data } = await axios.get<any, AxiosResponse<Setting>>(
      `${URL}/settings/active`  
    );
    return data;
  });
  return query;
}

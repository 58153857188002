import { useQuery } from 'react-query';
import { axios } from '../lib';
import { Plugin } from '../models';
import { URL } from '../config';

export function usePlugins() {
  const query = useQuery('plugins', async () => {
    const { data } = await axios.get<Plugin[]>(`${URL}/plugins`);
    return data;
  });
  return query;
}

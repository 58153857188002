function formatMoney(
  candidate: number | string | null | undefined,
  currency = '',
  decimalPoint = true,
  hiddenPoint = false
): string {
  if (!candidate && candidate !== 0) return '-';
  if (Number.isNaN(Number(candidate))) return '-';
  const numberFormat = new Intl.NumberFormat('es-VE', {
    style: 'currency',
    currency: 'USD',
  });
  const result = numberFormat
    .format(Number(candidate))
    .replace('USD', '')
    .replace(/( )/g, '')
    .trim();
  if (decimalPoint) {
    if (hiddenPoint) {
      const [integerTemp, decimalTemp] = result.split(',');
      return `${currency} ${integerTemp.replace(
        '.',
        ''
      )},${decimalTemp}`.trim();
    }
    return `${currency} ${result}`.trim();
  }
  const [integer, decimal] = result.split('.');
  return `${currency} ${integer.replace(/(,)/g, '.')},${decimal}`.trim();
}

export default formatMoney;

import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { axios } from '../lib';
import { Shipping } from '../models';
import { URL } from '../config';

export default function useShippings() {
  const query = useQuery<Shipping[], AxiosError>('shippings', async () => {
    const { data } = await axios.get(`${URL}/shippings`); 
    return data;
  });
  return query;
}



const URL_DEV = 'http://localhost:8080';


//const API_URL = 'api-e-sale.azurewebsites.net';
const API_URL = '';



export const URL = API_URL;   //process.env.NODE_ENV === 'production' ? API_URL  : URL_DEV;

export const PER_PAGE = 16;

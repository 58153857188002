import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const rows = 2;
const columns = 5;
const coverHeight = 250;
const coverWidth = 300;
const padding = 30;
const speed = 1;

const coverHeightWithPadding = coverHeight + padding;
const coverWidthWithPadding = coverWidth + padding;
const initial = 35;
const covers = Array(columns * rows).fill(1);

export default function ProductLoader({ ...props }: IContentLoaderProps) {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <div className="relative z-10 flex items-baseline justify-between pt-24 pb-6 border-b w-full">
          <ContentLoader
            speed={speed}
            width={columns * coverWidthWithPadding}
            height={rows * coverHeightWithPadding}
            className="w-full"
            {...props}
          >
            <rect
              x="0"
              y="0"
              rx="0"
              ry="0"
              width={columns * coverWidthWithPadding - padding}
              height="20"
            />

            {covers.map((g, i) => {
              const vy =
                Math.floor(i / columns) * coverHeightWithPadding + initial;
              const vx =
                (i * coverWidthWithPadding) % (columns * coverWidthWithPadding);
              return (
                <rect
                  key={i}
                  x={vx}
                  y={vy}
                  rx="0"
                  ry="0"
                  width={coverWidth}
                  height={coverHeight}
                />
              );
            })}
          </ContentLoader>
        </div>
      </div>
    </div>
  );
}

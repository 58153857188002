import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { axios } from '../lib';
import { Currency } from '../models';
import { URL } from '../config';

export default function useCurrencies() {
  const query = useQuery<Currency[], AxiosError>('currencies', async () => {
    const { data } = await axios.get(`${URL}/currencies`);
    return data;
  });
  return query;
}

import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { axios } from '../lib';
import { User, Client } from '../models';
import { URL } from '../config';

export function useMutationUser() {
  const client = useQueryClient();
  const mutation = useMutation<Client, AxiosError, Partial<User>>(
    async (values) => {
      const { data } = await axios.post<any, AxiosResponse<User>>(
        `${URL}/users/${values?._id}`, 
        {
          ...values,
        }
      );
      return data;
    },
    {
      onSuccess: (data) => {
        client.setQueryData('me', (prev: User) => {
          const user = {
            ...(prev ?? {}),
            ...(data ?? {}),
          };
          return user;
        });
      },
    }
  );
  return mutation;
}

export function useMutateClient() {
  const client = useQueryClient();
  const mutation = useMutation<Client, AxiosError, Partial<Client>>(
    async (values) => {
      const { data } = await axios.post<Partial<Client>, AxiosResponse<Client>>(
        `${URL}/clients/${values?._id}`, 
        {
          ...values,
        }
      );
      return data;
    },
    {
      onSuccess: (data) => {
        client.setQueryData('me', (prev: User) => {
          const user = {
            ...(prev ?? {}),
            client: {
              ...(prev?.client ?? {}),
              ...(data ?? {}),
            },
          };
          return user;
        });
      },
    }
  );
  return mutation;
}

import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import { UserContextProvider, AppContextProvider } from './src/context';
import './styles.css';
import 'react-toastify/dist/ReactToastify.css';

export const wrapRootElement = ({ element }) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <UserContextProvider>
          {element}
          <ToastContainer />
        </UserContextProvider>
      </AppContextProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

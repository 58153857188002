import React from 'react';
import useCurrentUser from '../hooks/useCurrentUser';
import { User } from '../models';

type TUserContext = {
  user?: User | null;
  setUser?: React.Dispatch<React.SetStateAction<User | null>>;
};

export const UserContext = React.createContext<TUserContext>({});

type UserContextProviderProps = {
  children: React.ReactNode;
};

export function UserContextProvider({ children }: UserContextProviderProps) {
  const { data, error, status } = useCurrentUser();
  const [user, setUser] = React.useState<User | null>(null);
  React.useEffect(() => {
    if (status === 'success') {
      if (!data?.client) {
        localStorage.removeItem('token');
        setUser(null);
      } else {
        setUser(data);
      }
    }
  }, [data, status]);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

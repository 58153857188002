import { ShopCart, Shipping, PromoCode } from '../models';

export type TShopCartTotal = {
  readonly subTotal: number;
  readonly extraFees: number;
  readonly taxes: number;
  readonly discount: number;
  readonly total: number;
};

export function calculateShopCartTotal(
  shopCart: ShopCart,
  shipping: Shipping | null = null,
  promo: PromoCode | null = null
): TShopCartTotal |any {
  const subTotal = shopCart?.items?.reduce(
    (_total, item) => _total + (item?.price ?? 0) * (item?.quantity ?? 0),
    0
  );
  let discount = 0;
  if (promo && promo.discount) {
    discount = promo.fixed
      ? promo?.discount ?? 0
      : ((promo?.discount ?? 0) / 100) * subTotal;
  }
  let taxes = 0;
  if (promo && promo.discount) {
    taxes = shopCart?.items?.reduce(
      (_total, item) =>
        _total +
        item?.taxes?.reduce(
          (totalTaxes, tax) =>
            totalTaxes + ((promo?.discount ?? 0) / 100) * (tax?.amount ?? 0),
          0
        ),
      0
    );
  } else {
    taxes = shopCart?.items?.reduce(
      (_total, item) =>
        _total +
        item?.taxes?.reduce(
          (totalTaxes, tax) => totalTaxes + (tax?.amount ?? 0),
          0
        ),
      0
    );
  }
  let extraFees = 0;
  if (shipping && shipping.price) {
    extraFees = shipping?.price ?? 0;
  }

  const total = subTotal - discount + extraFees + taxes;
  return {
    subTotal,
    extraFees,
    discount,
    taxes,
    total,
  } as const;
}

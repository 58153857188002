import React from 'react';
import { Currency, Setting } from '../models';
import { useCurrencies, usePlugins, useSetting } from '../hooks';
import { HomeLoader } from '../components/loaders';

type TAppContext = {
  lang?: string;
  setLang?: React.Dispatch<React.SetStateAction<string>>;
  currencies?: Currency[];
  currency?: Currency;
  setCurrency?: React.Dispatch<React.SetStateAction<Currency>>;
  setting?: Setting;
};

export const AppContext = React.createContext<TAppContext>({
  lang: 'es',
  currency: null,
});

interface IAppContextProviderProps {
  children: React.ReactNode;
}

export function AppContextProvider({ children }: IAppContextProviderProps) {
  const [lang, setLang] = React.useState('es');
  const [currency, setCurrency] = React.useState<Currency>(null);
  const { data: dataCurrencies, status: statusCurrencies } = useCurrencies();
  const { data: dataSetting, status: statusSetting } = useSetting();
  const { data: dataPlugins, status: statusPlugins } = usePlugins();

  React.useEffect(() => {
    if (statusPlugins === 'success') {
      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const html = dataPlugins
          ?.map((plugin) => plugin?.html ?? '')
          ?.join(' ');
        const div = document.createElement('div');
        div.innerHTML = html;
        div.querySelectorAll('script').forEach((s) => {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          if (s.innerHTML !== '') {
            script.innerHTML = s.innerHTML;
            document.head.appendChild(script);
            return;
          }
          script.src = s.src;
          document.head.appendChild(script);
        });
      }
    }
  }, [dataPlugins, statusPlugins]);

  React.useEffect(() => {
    if (statusCurrencies === 'success') {
      setCurrency(dataCurrencies?.[0] ?? null);
    }
  }, [dataCurrencies, statusCurrencies]);

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      if (dataSetting) {
        dataSetting?.info?.colors?.forEach((color, idx) => {
          if (idx === 0) {
            document.documentElement.style.setProperty(
              '--primary-50',
              color ?? '#4f46e5'
            );
          } else {
            document.documentElement.style.setProperty(
              `--primary-${idx * 100}`,
              color ?? '#4f46e5'
            );
          }
        });
      }
    }
  }, [dataSetting]);

  return (
    <AppContext.Provider
      value={{
        lang,
        currency,
        setLang,
        setCurrency,
        currencies: statusCurrencies === 'success' ? dataCurrencies : [],
        setting: statusSetting === 'success' ? dataSetting : {},
      }}
    >
      {statusCurrencies === 'loading' || statusSetting === 'loading' ? (
        <HomeLoader />
      ) : null}
      {statusCurrencies === 'success' && statusSetting === 'success'
        ? children
        : null}
    </AppContext.Provider>
  );
}

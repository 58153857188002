import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { axios } from '../lib';
import { User, Order } from '../models';
import { URL } from '../config';

interface CreateOrder {
  clientSecret: string;
  order: Order;
  user: User;
}

interface CreateOrderInput {
  shopCartId: string;
  shippingId: string;
  addressId: string;
  userId: string;
  promoCodeId?: string;
}

interface CreateOrderPaypal {
  clientSecret: string;
  order: Order;
  user: User;
}

interface CreateOrderInputPaypal {
  shopCartId: string;
  shippingId: string;
  addressId: string;
  userId: string;
  promoCodeId?: string;
  index?: any;
  shippingSelected?: any;
}

export function useCreateOrder() {
  const client = useQueryClient();
  const mutation = useMutation<CreateOrder, AxiosError, CreateOrderInput>(
    async (values: CreateOrderInput) => {
      const { data } = await axios.post<
        CreateOrderInput & { currency: string; paymentMethodTypes: string[] },
        AxiosResponse<CreateOrder>
      >(`${URL}/payment/secret`, { 
        currency: 'usd',
        paymentMethodTypes: ['card'],
        shopCartId: values?.shopCartId ?? null,
        shippingId: values?.shippingId ?? null,
        addressId: values?.addressId ?? null,
        userId: values?.userId ?? null,
        promoCodeId: values?.promoCodeId ?? undefined,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        client.setQueryData('me', data?.user ?? null);
      },
    }
  );
  return mutation;
}

export function useCreateOrderPaypal() {
  const client = useQueryClient();
  const mutation = useMutation<CreateOrderPaypal, AxiosError, CreateOrderInputPaypal>(
    async (values: CreateOrderInputPaypal) => {
      const { data } = await axios.post<
        CreateOrderInputPaypal & { currency: string; paymentMethodTypes: string[] },
        AxiosResponse<CreateOrderPaypal>
      >(`${URL}/payment/secret-paypal`, { 
        currency: 'usd',
        paymentMethodTypes: ['card'],
        shopCartId: values?.shopCartId ?? null,
        shippingId: values?.shippingId ?? null,
        addressId: values?.addressId ?? null,
        userId: values?.userId ?? null,
        promoCodeId: values?.promoCodeId ?? undefined,
        index: values?.index ?? null,
        shippingSelected: values?.shippingSelected ?? null,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        client.setQueryData('me', data?.user ?? null);
      },
    }
  );
  return mutation;
}

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-tsx": () => import("./../../../src/pages/[...].tsx" /* webpackChunkName: "component---src-pages-tsx" */)
}


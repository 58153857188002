import Axios from 'axios';

const axios = Axios.create({
  baseURL: process.env.GATSBY_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-access-token':
      typeof window !== 'undefined' ? localStorage.getItem('token') : undefined,
  },
});

export default axios;

import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { axios } from '../lib';
import { Question } from '../models';
import { URL } from '../config';

export function useQuestions() {
  const query = useQuery<any, AxiosError, Question[]>('questions', async () => {
    const { data } = await axios.get<any, AxiosResponse<Question[]>>(
      `${URL}/questions`
    );
    return data;
  });
  return query;
}

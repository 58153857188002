import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

export default function HomeLoader({ ...rest }: IContentLoaderProps) {
  return (
    <ContentLoader
      viewBox="0 0 265 230"
      speed={2}
      width={1000}
      height={600}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className="mx-auto"
      {...rest}
    >
      <rect x="0" y="8" rx="0" ry="0" width="40" height="18" />
      <circle cx="492" cy="16" r="8" />
      <circle cx="472" cy="16" r="8" />
      <rect x="362" y="8" rx="7" ry="7" width="96" height="16" />

      <rect x="0" y="39" rx="0" ry="0" width="34" height="8" />
      <rect x="50" y="39" rx="0" ry="0" width="36" height="8" />
      <rect x="102" y="39" rx="0" ry="0" width="34" height="8" />
      <rect x="152" y="39" rx="0" ry="0" width="34" height="8" />
      <rect x="202" y="39" rx="0" ry="0" width="36" height="8" />
      <rect x="254" y="39" rx="0" ry="0" width="34" height="8" />

      <rect x="477" y="39" rx="0" ry="0" width="10" height="8" />

      <rect x="19" y="64" rx="0" ry="0" width="465" height="155" />

      <rect x="18" y="225" rx="0" ry="0" width="141" height="38" />
      <rect x="182" y="225" rx="0" ry="0" width="141" height="38" />
      <rect x="343" y="225" rx="0" ry="0" width="141" height="38" />
      <rect x="18" y="270" rx="0" ry="0" width="141" height="38" />
      <rect x="182" y="270" rx="0" ry="0" width="141" height="38" />
      <rect x="343" y="270" rx="0" ry="0" width="141" height="38" />
    </ContentLoader>
  );
}

import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { axios } from '../lib';
import { User } from '../models';
import { URL } from '../config';

export default function useSignIn() {
  const client = useQueryClient();
  const mutation = useMutation<
    { user: User; token: string },
    AxiosError,
    { email: string; password: string },
    unknown
  >(
    async (values: { email: string; password: string }) => {
      const { data } = await axios.post<{ user: User; token: string }>(
        `${URL}/sign-in`, 
        values
      );
      return data;
    },
    {
      onSuccess: async (data: any) => {
        localStorage.setItem('token', data.token);
        await client.setQueryData(`user:${data?.user?._id}`, data?.user);
      },
    }
  );
  return mutation;
}

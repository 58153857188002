import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { axios } from '../lib';
import { Order } from '../models';
import { URL } from '../config';

export function useOrder(_id: string) {
  const query = useQuery<any, AxiosError, Order>(`order:${_id}`, async () => {
    const { data } = await axios.get<Order>(`${URL}/orders/${_id}`);  
    return data;
  });
  return query;
}

export function useOrders(_id: string) {
  const query = useQuery<any, AxiosError, Order[]>(
    `orders-client:${_id}`,
    async () => {
      if (!_id) {
        return null;
      }
      const { data } = await axios.get<Order[]>(`${URL}/orders/client/${_id}`);
      return data;
    }
  );
  return query;
}

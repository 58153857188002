import { useMutation, useQueryClient } from 'react-query';
import { axios } from '../lib';
import { User } from '../models';
import { URL } from '../config';

export default function useSignUp() {
  const client = useQueryClient();
  const mutation = useMutation(
    async (values: any) => {
      const { data } = await axios.post<{ user: User; token: string }>(
        `${URL}/sign-up`, 
        values
      );
      return data;
    },
    {
      onSuccess: async (data: any) => {
        localStorage.setItem('token', data.token);
        await client.setQueryData(`user:${data?.user?._id}`, data?.user);
      },
    }
  );
  return mutation;
}
